import styled from 'styled-components'
import { Box } from '../../../atoms'

export const GroupedTestimonials = styled(Box)`
  margin-left: -15px;
  margin-right: -15px;

  .alice-carousel {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    > div:first-of-type {
      flex: 0 0 100%;
      max-width: 100%;
    }

    &__wrapper {
      padding-bottom: 8px;
    }

    &__stage {
      display: flex;

      &-item {
        display: block;
        flex: 0 0 auto;
        height: unset;
      }
    }

    &__dots {
      flex: 1;
      margin: 24px 0 0 15px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }

    &__prev-btn,
    &__next-btn {
      width: auto;
      padding: 0;
      margin-top: 24px;
      margin-right: 16px;
    }
  }
`

export const TestimonialOperatorGrouped = styled(Box)`
  .gatsby-image-wrapper {
    max-width: 32px;
    height: auto;
  }
`
