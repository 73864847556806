import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

interface KpiOperatorIconProps {
  operator: 'plus' | 'minus' | 'multiply' | 'divided'
}

const KpiOperatorIcon = ({ operator }: KpiOperatorIconProps) => {
  return (() => {
    switch (operator) {
      case 'plus':
        return (
          <StaticImage
            formats={['auto']}
            draggable={false}
            src={'../../images/operators/operator_plus.png'}
            alt={'Plus icon'}
            placeholder={'none'}
            width={40}
          />
        )
      case 'minus':
        return (
          <StaticImage
            formats={['auto']}
            draggable={false}
            src={'../../images/operators/operator_minus.png'}
            alt={'Minus icon'}
            placeholder={'none'}
            width={40}
          />
        )
      case 'multiply':
        return (
          <StaticImage
            formats={['auto']}
            draggable={false}
            src={'../../images/operators/operator_multiply.png'}
            alt={'Multiply icon'}
            placeholder={'none'}
            width={40}
          />
        )
      case 'divided':
        return (
          <StaticImage
            formats={['auto']}
            draggable={false}
            src={'../../images/operators/operator_divided.png'}
            alt={'Divided icon'}
            placeholder={'none'}
            width={40}
          />
        )
      default:
        return null
    }
  })()
}

export default KpiOperatorIcon
